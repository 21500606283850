import classes from './Header.module.css';
import { Link } from 'react-router-dom';
import React,{ useState,useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { PlateContext } from '../../../store/GenreContext';
// alert 
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const Header = (props) => {
  
  const [active,setActive] = useState('home');
  const ctx = useContext(PlateContext);

  const [currency, setCurrency] = useState();
  const [language, setLanguage] = useState();
  
  const location = useLocation();

  useEffect(() => {
    const currency_localStorage = localStorage.getItem("currency-x-x");
    const language_localStorage = localStorage.getItem("language_menumind");

    if(language_localStorage != null) {
      setLanguage(language_localStorage);
      ctx.setCurrentLanguage(language_localStorage);
    }else {
      // default_lang code
      ctx.languages?.forEach(item => {
        if(item.default_lang == 1) {
          localStorage.setItem("language_menumind", item.code + "/" + item.id + "/" + "ltr" );
          setLanguage(item.code + "/" + item.id + "/" + "ltr");
          ctx.changeCurrentLanguage(item.code + "/" + item.id + "/" + "ltr");
          // localStorage.setItem();
        }
      })
    }

    if(currency_localStorage != null){
      setCurrency(currency_localStorage)
      ctx.changeCurrentCurrency(currency_localStorage);
    }else {
      ctx.currency?.forEach(item => {
        if(item.main_currency == 1) {
          localStorage.setItem("currency-x-x", item.currency_shortuct + "/" + item.id);
          setCurrency("currency", item.currency_shortuct + "/" + item.id);
          ctx.changeCurrentCurrency(item.currency_shortuct + "/" + item.id);
        }
      });
      }

      // ctx.fetchPlatesByCategory();
  },[ctx.currency,ctx.languages,currency,language])

  // swal instance
  const MySwal = withReactContent(Swal)
  
  // handle currency 
  const handleCurrency = (event) => {
    const value = event.target.value;
    MySwal.fire({
      title: 'Are you sure?',
      text: "Your order items will be removed !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm'
    }).then((result) => {

      if (result.isConfirmed) { 
        localStorage.setItem("currency-x-x", value)
        setCurrency(value);
        ctx.changeCurrentCurrency(value);
      }
      
    })
  };
  
  // handle language
  const handleChange = (event) => {
    const value = event.target.value;

    MySwal.fire({
      title: 'Are you sure?',
      text: "Your order items will be removed !",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) { 
        localStorage.setItem("language_menumind", value)
        setLanguage(value);
        ctx.changeCurrentLanguage(value);
      }
    })
   
  };

  // get active link
  useEffect(() => {
    const path = location.pathname;
    if (path === '/about') {
      setActive('about');
    } else {
      setActive('home');
    }
  }, [window.location.pathname]);


  

  
  // .data[0].code
  return (
      <div className={`contain ${classes.flexContainer}`}>
        <div className={classes.left}>
          
          <Link to={'/'} className={`${classes.menu} ${classes.btn} ${active==='home'?classes.active : "  "}`}>Menu</Link>
          <Link to={'/about'} className={`${classes.menu} ${classes.btn} ${active==='about'?classes.active : ""}`}>About</Link>
        </div>
        <div className={classes.right}>

          {/* <select className={classes.langbtn} id="language" value={language} onChange={handleChange} >
            {
              ctx.languages?.map(item => {
                const curr_lang = localStorage.getItem("language_menumind");
                return curr_lang?.split("/")[0] != item.code ? 
                <option key={item.id} className={classes.option} value={item.code + "/ltr"}>{item.code}</option>
                : 
                <option key={item.id} defaultChecked className={classes.option} value={item.code + `/${item.id}` + "/ltr"}>{item.code}</option>
              })
            }
          </select> */}

          <select className={` ${classes.currencybtn} ${classes.langbtn}`} id="currency" value={currency} onChange={handleCurrency} >
           {
            ctx.currency?.map(item => {
              const curr_currency = localStorage.getItem("currency-x-x");
              return curr_currency?.split("/")[0] !== item.currency_name ? 
              <option key={item.id} className={classes.option} value={item.currency_shortuct}>{item.currency_shortuct}</option>
              :  <option key={item.id} defaultChecked={true} className={classes.option} value={item.currency_shortuct}>{item.currency_shortuct}</option>
            })
           }
          </select>


        </div>
      </div>
      


  )
}


export default React.memo(Header);