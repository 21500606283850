import classes from "./Order.module.css";
import Subtotal from "../../ui/subtotal/Subtotal";
import {useContext, useEffect, useState} from "react";
import OrderPlates from "./OrderPlates";
import {OrderContext} from "../../../store/GenreContext";

const Order = (props) => {
  const ctx = useContext(OrderContext);
  const [order, setOrder] = useState([]);
  const [orderState, setOrderState] = useState("neutral");
  const [phone, setPhone] = useState("+96170740808");

  // GET ABOUT CONTENT
  // useEffect(() => {
  //   fetchHelper(
  //     "https://catalogcms2.menumind.net/v1_0_0-data/about-us",
  //     "GET"
  //   ).then((data) => {
  //     setPhone(JSON.parse(data.data).phone_number_one);
  //   });
  // }, []);

  const {currentLanguage: language} = ctx;
  useEffect(() => {
    setOrder(ctx.orderPlates);
  }, [ctx.orderPlates]);

  useEffect(() => {
    if (ctx.orderPlates.length === 0 && orderState !== "success")
      props.onClickCloseOrder();
  }, [ctx.orderPlates]);

  function clearOrder(e) {
    if (ctx.orderPlates.length !== 0) {
      const whatsappMessage = ctx.getOrderReadyToWhatsapp();
      window.open(
        // `https://wa.me/76023998?text=%0A${whatsappMessage}`,
        `https://wa.me/${phone}?text=Hello,%20I%20would%20like%20to%20order:%0A${whatsappMessage}`,
        "_blank"
      );
      ctx.clearOrder();
      setOrder(ctx.orderPlates);
      setOrderState("success");
    } else if (ctx.orderPlates.length === 0 && orderState === "success") {
      e.preventDefault();
      props.onClickCloseOrder();
      return;
    }
  }

  // LANGUAGE COMPONENT
  // const languageArr = language?.split("/");
  // const languageId = languageArr[1];
  // const languagePosition = languageArr[2];

  return (
    <div
      className={`${classes.col} ${
        props.state === "entered"
          ? classes.entered
          : props.state === "entering"
          ? classes.entering
          : props.state === "exiting"
          ? classes.exiting
          : null
      }`}>
      <div className={classes.sticky}>
        <Subtotal
          // languagePosition={languagePosition}
          fixed={classes.orderTop}></Subtotal>
        <span
          className={`${classes.hide} hide`}
          onClick={props.onClickCloseOrder}>
          <i className="fas fa-x" />
        </span>
      </div>
      {ctx.orderPlates.length !== 0 ? (
        <div className={classes.overflow}>
          <div>
            {order.map((plate) => {
              return <OrderPlates key={plate.id} plate={plate} />;
            })}
          </div>
        </div>
      ) : orderState === "success" ? (
        <h2 className="p-md-5 p-sm-4 ps-3 text-success">
          Thank you for your order!
        </h2>
      ) : null}
      <Subtotal
        // languagePosition={languagePosition}
        fixed={classes.order}></Subtotal>
      <div className="w-100">
        <button
          onClick={clearOrder}
          target={"_blank"}
          // href={`https://wa.me/76023998?text=Hello,%20I%20would%20like%20to%20order:%0A${whatsappMessage}`}
          className={`${classes.orderButton} ${
            orderState === "success" ? classes.orderSuccess : null
          }`}>
          {orderState === "success" ? "Return To Menu" : "ORDER NOW"}
        </button>
      </div>
    </div>
  );
};

export default Order;
