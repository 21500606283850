import classes from "./MiniOrder.module.css";
import Order from "./Order";
import Subtotal from "../../ui/subtotal/Subtotal";
import {useState, useEffect, useContext} from "react";
import {Transition} from "react-transition-group";
import {PlateContext} from "../../../store/GenreContext";

const MiniOrder = (props) => {
  const [showOrder, setShowOrder] = useState(false);
  const ctx = useContext(PlateContext);

  const {currentLanguage: language} = ctx;
  useEffect(() => {
    document.body.style.overflow = showOrder ? "hidden" : "initial";
    return () => {
      document.body.style.overflow = "initial";
    };
  }, [showOrder]);

  // LANGUAGE COMPONENT
  const languageArr = language?.split("/");
  let languageId;
  let languagePosition;
  if (languageArr) {
    languageId = languageArr[1];
    languagePosition = languageArr[2];
  }

  function onClickOpenOrder(e) {
    e.stopPropagation();
    setShowOrder(true);
  }

  function onClickCloseOrder() {
    setShowOrder(false);
  }

  return (
    <div>
      <Transition in={showOrder} timeout={300} unmountOnExit mountOnEnter>
        {(state) => (
          <Order
            languagePosition={languagePosition}
            state={state}
            onClickCloseOrder={onClickCloseOrder}
          />
        )}
      </Transition>
      {!showOrder && (
        <Subtotal
          languagePosition={languagePosition}
          onClickOpenOrder={onClickOpenOrder}
          fixed={classes.fixed}
        />
      )}
    </div>
  );
};

export default MiniOrder;
