import classes from "./Genre.module.css";
import {useContext, useState} from "react";
import {GenreContext} from "../../../store/GenreContext";
import {Link} from "react-scroll";
import default_image from "../../../images/default_image.png";

import {InView} from "react-intersection-observer";
import ProgressiveImage from "react-progressive-graceful-image";

const Genre = (props) => {
  const {src, id, label, setActive, activeId} = props;
  const [hideImage, setHideImage] = useState(false);

  const ctx = useContext(GenreContext);

  function fetchPlates() {
    ctx.fetchPlatesByCategory(id);
  }

  const offset =
    window.innerWidth < window.innerHeight
      ? (100 - 60) * (window.innerHeight / 100)
      : 100 * (window.innerHeight / 100);

  let imageSrc = `${src}`;

  var splitImage = imageSrc.split("/");

  var blurredImageSrc = imageSrc.replace(
    splitImage[splitImage.length - 1],
    "blures/" + splitImage[splitImage.length - 1]
  );
  var thumbnailSrc = imageSrc.replace(
    splitImage[splitImage.length - 1],
    "thumbnails/" + splitImage[splitImage.length - 1]
  );

  //api.whatsapp.com/send/?phone=70778877&text=Hello%2C+I+would+like+to+order%3A%0AESSENTIALS+3-STRIPES+HOODIE+x3%0A%0ADESIGNED+FOR+GAMEDAY+FULL-ZIP+JACKET+x3%0A%0A%2ATotal%2A%3A22176000+L.L&type=phone_number&app_absent=0

  https: return (
    // <Link
    //   offset={offset}
    //   smooth={true}
    //   className={`${classes.main} ${classes.box_shadow} ${
    //     activeId === id ? classes.active : null
    //   }`}
    //   duration={200}
    //   to="topSection">
    //   <div className={classes.image}>
    //   </div>
    //   <h2 className={classes.label} onClick={fetchPlates}>
    //     {label}
    //   </h2>
    //   <div className={classes.overlay} onClick={fetchPlates}></div>
    // </Link>

    <Link
      offset={offset}
      smooth={true}
      className={`${classes.main} ${classes.box_shadow} ${
        activeId === id ? classes.active : null
      }`}
      style={{height:"8.4vh"}} 
      duration={0}
      to="topSection">
      <h2 className={classes.label} onClick={fetchPlates}>
        {label}
      </h2>
    </Link>

  );
};

export default Genre;
