
function CarouselArrow(props) {
  const { className, style, onClick,bgDark } = props;

  const isDark = bgDark == true ? "#888" : null;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background:isDark,borderRadius:"50%" }}
      onClick={onClick}
    />
  );
}

export default CarouselArrow;