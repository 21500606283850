import React, {useState} from "react";
import AddRemCount from "../../ui/AddRemCount";
import ImageFocus from "../Focus/ImageFocus";
import PlateFocus from "../Focus/PlateFocus";
import classes from "./Plates2.module.css";
import imageDUMMY from "../../../images/placeholder-restaurant.png";
import {useEffect} from "react";
import {getMenuItemPrice, getCurrency_updated} from "../../hooks/fetchHelper";

import {InView} from "react-intersection-observer";
import ProgressiveImage from "react-progressive-graceful-image";

const PlatesInner = (props) => {
  const {language, languagePosition} = props;
  const [isOpen, setIsOpen] = useState(false);
  const [imageHasLoaded, setImageHasLoaded] = useState(false);
  
  const {
    menu_name,
    menu_image,
    menu_price,
    menu_description,
    menu_id:id,
    count
    } = props.plate;
    
    
    const [price, setPrice] = useState();
  
    let currency_shortcut= localStorage.getItem("currency-x-x")


  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "initial";
    return () => {
      document.body.style.overflow = "initial";
    };
  }, [isOpen]);

  const [showPlateFocus, setShowPlateFocus] = useState(false);
  const [showImageFocus, setShowImageFocus] = useState(false);

  function onClickFocusPlateHandler(e) {
    e.stopPropagation();
    setIsOpen(true);
    setShowPlateFocus(true);
  }

  function onClickFocusImageHandler(e) {
    e.stopPropagation();
    setIsOpen(true);
    setShowImageFocus(true);
  }

  // stops the function propagation to its chosen child element
  function onClickStop(e) {
    e.stopPropagation();
  }

  function onClickHidePlateFocus() {
    setIsOpen(false);
    setShowPlateFocus(false);
  }

  function onClickHideImageFocus() {
    setIsOpen(false);
    setShowImageFocus(false);
  }

  let imageSrc = `https://aboudeeb.com.de/admin/admin/Uploads/images/${menu_image}`;

  function checkImageState(e) {
    setImageHasLoaded(true);
  }

  const updatedTitle =
    menu_name.trim().charAt(0).toUpperCase() +
    menu_name.substring(1).trim().toLowerCase();

  const p = document.createElement("p");
  p.innerHTML = menu_description;

  const updatedmenu_description =
    p.textContent.length > 70
      ? p.textContent.trim().slice(0, 63) + "..."
      : p.textContent;
  // RATE

  // const to_currency = localStorage.getItem("currency");
  // let rateObj = getCurrency_updated(to_currency.split("/")[1],item_currency_id,props.exCurrency,props.currency);
  //  rateObj = getRate(1,props.currencyId,props.exCurrency,props.currency);
  // const updatedmenu_price =`${(menu_price * rateObj.rate).toLocaleString()} ${rateObj.shortcut}`

  // blur images
  // let updatedmenu_price = menu_price;
  // var splitImage = imageSrc.split("/");

  // var blurredImageSrc = imageSrc.replace(
  //   splitImage[splitImage.length - 1],
  //   "blures/" + splitImage[splitImage.length - 1]
  // );
  // var thumbnailSrc = imageSrc.replace(
  //   splitImage[splitImage.length - 1],
  //   "thumbnails/" + splitImage[splitImage.length - 1]
  // );



  useEffect(() => {
    if( menu_price == null) {
      return;
    }
    console.log("HERE");
    (async function x() {
      const price = await getMenuItemPrice(menu_price)
      setPrice(price);
    })()
  },[])

  return (
    <>
      <div
        key={id}
        className={`contain ${classes.flex} ${
          languagePosition == "rtl" ? classes.rightToLeft : null
        }`}
        onClick={onClickFocusPlateHandler}>
        <div className={classes.flexInner}>
          {menu_image !== null && (
            <InView>
              <ProgressiveImage src={imageSrc}>
                {(src) => (
                  <img
                    src={src}
                    alt="..."
                    loading="lazy"
                    variant="top"
                    onClick={onClickFocusImageHandler}
                    className="!max-w-[160px]"
                  />
                )}
              </ProgressiveImage>
            </InView>
          )}

          <div className={classes.rightSide}>
            <div>
              <span className={classes.title}>{updatedTitle}</span>
              <span className={classes.menu_description}>{updatedmenu_description}</span>
            </div>
            <span className={classes.menu_price}>
              {price} {currency_shortcut}
            </span>
          </div>
          <div
            className={`${
              languagePosition == "rtl" ? classes.mb2 : classes.mb
            }`}>
            <div onClick={onClickStop}>
              <AddRemCount
                setImageHasLoaded={(val) => setImageHasLoaded(val)}
                id={id}
                qty={count}
                plate={props.plate}
              />
            </div>
          </div>
        </div>
      </div>
      {showPlateFocus && (
        <PlateFocus plate={props.plate} is_only_image={false} modal_id="modal_no_image" isOpen={isOpen} onClickHide={onClickHidePlateFocus} />
        )}
      {showImageFocus && imageSrc !== null && (
        <PlateFocus is_only_image={true} plate={props.plate} modal_id="modal_image" isOpen={isOpen} onClickHide={onClickHideImageFocus} />
        // <ImageFocus image={imageSrc} isOpen={isOpen} onClickHide={onClickHideImageFocus} />
      )}
    </>
  );
};

export default PlatesInner;
