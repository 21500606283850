

const fetchHelper = async (url,method,body) => {
  let fetched;
  // let controller = new AbortController();

  try {
    if(method.toUpperCase() !== 'GET') {
      fetched = await fetch(url, {
      method,
      body,
      headers:{"Content-Type":"Application/json"}
    });
    if(!fetched.ok) 
      throw new Error("Something went wrong") 
    else 
      return {message:{error:false,content:"Fetched successfuly"},data:body};

  }else {
    fetched = await fetch(url)
    const data_json = await fetched.json();
    if(!fetched.ok) throw new Error("Something went wrong")
    return {message:{error:false,content:"Fetched successfuly"},data:data_json};
  }
  
  } catch (error) {
      return {message:{error:true,content:"Something went wrong"},data: null}
  }
}

export const sortArray = (plates, categories) => {
  // console.log(categories)
  // console.log(plates)
  let grouped = categories?.map(category => [
    category,
    plates.filter(obj => {
     return obj.category_id === category.id
    })
  ]);
  return grouped;
}


export const getMenuItemPrice = async (menu_price, toLocale = true) => {
      let lastPrice = menu_price;
      const chosenCurrency = localStorage.getItem("currency-x-x");
      let dollarRate = localStorage.getItem("dollarRate");
      const dollarRate_ttl = localStorage.getItem("dollarRate_ttl");
      // price by entered in lebanese
      if(chosenCurrency != null && chosenCurrency.includes("$")) {
          if(dollarRate == null || new Date().getTime() > dollarRate_ttl) {
            const res = await fetchHelper(
              "https://aboudeeb.com.de/admin/apis/SiteController.php/dollarRate",
              "GET"
            )
            dollarRate = res.data[0].rate; // here is the dollar rate
            localStorage.setItem("dollarRate", dollarRate);
            localStorage.setItem("dollarRate_ttl", new Date().getTime() + 50000); // time to live
          }
        lastPrice = Math.round(((menu_price / dollarRate) * 10)) / 10;
      }

     if(chosenCurrency == null || chosenCurrency.includes("L.L"))
        lastPrice = menu_price;

     if(chosenCurrency == null) 
         localStorage.setItem("currency-x-x","L.L")

        

    if(toLocale) {
      return lastPrice.toLocaleString();
    }else {
      return lastPrice;
    }
}



// export const getMenuItemPrice = async (menu_price, toLocale = true) => {
//       let lastPrice = menu_price;
//       const chosenCurrency = localStorage.getItem("currency-x-x");
//       let dollarRate = localStorage.getItem("dollarRate");
//       const dollarRate_ttl = localStorage.getItem("dollarRate_ttl");

//       if(chosenCurrency != null && chosenCurrency.includes("L.L")) {
//           if(dollarRate == null || new Date().getTime() > dollarRate_ttl) {
//             const res = await fetchHelper(
//               "https://aboudeeb.com.de/admin/apis/SiteController.php/dollarRate",
//               "GET"
//             )
//             dollarRate = res.data[0].rate; // here is the dollar
//             localStorage.setItem("dollarRate", dollarRate);
//             localStorage.setItem("dollarRate_ttl", new Date().getTime() + 50000); // time to live
//           }
//         lastPrice = menu_price * dollarRate;
//       }

//      if(chosenCurrency == null || chosenCurrency.includes("$"))
//         lastPrice = menu_price;

//      if(chosenCurrency == null) 
//          localStorage.setItem("currency-x-x","$")

        

//     if(toLocale) {
//       return lastPrice.toLocaleString();
//     }else {
//       return lastPrice;
//     }
// }


// currency in the select in the header is always the "to". Currency in the product is the "from"
export const getRate = (from, to, rates, ratesSelect) => {
  let mainCurrency;
  let rateObject;
  let rateShortcut;

  if(ratesSelect.data) {
    mainCurrency = ratesSelect.data.find(item => item.main_currency == 1);
  }

  if(rates.data && ratesSelect.data ) {
    rateObject = rates.data.find(rate => mainCurrency?.id == from && (rate.to_currency || mainCurrency) == to);
    rateShortcut = ratesSelect.data.find(rate => rate.id == to);
  }
  
  if (rateObject) {
    return {rate:rateObject.rate, shortcut:rateShortcut.currency_shortuct};
  }

  return {rate:1, shortcut:mainCurrency?.currency_shortuct || ""}; // no exchange rate found
}


export const getCurrency_updated = (to, from,exCurr, curr) => {

  let result = {rate:1, shortcut:"L.L"};
  exCurr?.data.forEach(item => {
    // console.log(item);
    if(item.from_currency == from && item.to_currency == to) {
      curr.data.forEach(currency => {
        if(currency.id == item.to_currency) {
          result = {rate:item.rate, shortcut:currency.currency_shortuct}
          return;
        }
      })
    }    
  });
  return result; // no exchange rate found
}

export const getLanguage = () =>{
  return null;
}


export default fetchHelper;