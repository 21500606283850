import React, {useEffect, useState} from "react";
import fetchHelper, {sortArray} from "../components/hooks/fetchHelper";


export const OrderContext =  React.createContext({
  onClickAddOne: (id) => {},
  onClickRemoveOne: (id) => {},
  orderPlates:[],
  fetchedPlates :[],
  getOrderReadyToWhatsapp: () => {}
});

export const GenreContext = React.createContext({
  // orderPlates: [],
  getActiveCategory: "",
  getSubGenres: [],
  showSubGenrePlates: Boolean,
  genreOpen: Number,
  error: String || null,
  fetchedCategories: [],
  fetchPlatesByCategory: (label) => {},
});

export const PlateContext = React.createContext({
  fetchPlatesBySubGenre: (subGenreId) => {},
  subGenrePlates: [],
 
  getCount: Boolean,
  isLoading: Boolean,
  platesContainerIsEmpty: Boolean,
  currency: {},
  exCurrency: {},
  changeCurrentCurrency: () => {},
  currentCurrency: String,
  languages: [],
  setCurrentLanguage: String,
  currentLanguage: String,
});

// let fetchedPlates = [];
let subGenrePlates = [];
// let orderPlates = [];

let platesContainerIsEmpty = false;


export const OrderContextProvider = (props) => {
  const [orderPlates, setOrderPlates] = useState([]);



  
  function onClickAddOne(plate) {
    // fetch for item with id == id and then add 1 to its count

    // const foundItem = fetchAllPlates.find((item) => item.menu_id === id);
    // const foundItemIndex = fetchAllPlates.findIndex((item) => item.menu_id === id);
    const foundOrderItemIndex = orderPlates.findIndex((item) => item.menu_id === plate.menu_id);

    // const newFetchArr = [...fetchAllPlates];
    // newFetchArr[foundItemIndex].count++;
    
    // setFetchAllPlates(old => {
    //   const newFetchArr = [...old];
    //   newFetchArr[foundItemIndex].count++;
    //   return newFetchArr;
    // });

    let newOrderArr = [...orderPlates];
    if (foundOrderItemIndex === -1) {
      plate.count = 1;
      newOrderArr.push(plate);
    }else {
      newOrderArr[foundOrderItemIndex].count++;
    }

    setOrderPlates(newOrderArr);
    // console.log(fetchedPlates)

    // add order to ls
    // const orderOld = localStorage.getItem("order");

    // if(orderOld) {

    //   const orderOld_toArr = JSON.parse(orderOld);
    //   const found = orderOld_toArr.findIndex(item => item.id === id);

    //   found === -1 ? orderOld_toArr.push(foundItem) : orderOld_toArr[found].count += 1;
    //   // localStorage.setItem("order",JSON.stringify(orderOld_toArr));
    // }else {
    //   // adding 5h in milliseconds to the date
    //   // localStorage.setItem("orderExpDate", (Date.now() + (5 * 60 * 60 * 1000)));
    //   // localStorage.setItem("order",JSON.stringify([foundItem]));
    // }
      // setOrderPlates(newOrderArr);
    console.log(orderPlates)

    return plate.count;
  }

  // ADD ONE TO THE COUNT OF A PLATE
  function onClickRemoveOne(plate) {
    // fetch for item with id == id and then add 1 to its count
    // const foundItem = fetchAllPlates.find((item) => item.menu_id === id);
    // const foundItemIndex = fetchAllPlates.findIndex((item) => item.menu_id === id);

    const foundOrderItem = orderPlates.find((item) => item.menu_id === plate.menu_id);
    const foundOrderItemIndex = orderPlates.findIndex((item) => item.menu_id === plate.menu_id);

    let newOrderArr = [...orderPlates];

    if (plate.count == 1) {
        newOrderArr[foundOrderItemIndex].count = 0;
      newOrderArr = newOrderArr.filter(item => {
        return item.menu_id !== plate.menu_id
      })

      console.log(newOrderArr);
    }

    // const newFetchedArr = [...fetchAllPlates];
    // newFetchedArr[foundItemIndex].count--;
    // setFetchAllPlates(newFetchedArr);

    if (foundOrderItemIndex !== -1) {
      if (foundOrderItem.count === 0) {
        newOrderArr = newOrderArr.filter((item) => item.id !== plate.menu_id);
      }else {
        newOrderArr[foundOrderItemIndex].count--;
      }
    }

    setOrderPlates(newOrderArr)

    // const orderOld = localStorage.getItem("order");
    // if(orderOld) {
    //   let orderOld_toArr = JSON.parse(orderOld);
    //   const found = orderOld_toArr.findIndex(item => item.id === id);
    //   if(found !== -1) {
    //     const found_forCount = orderOld_toArr.find(item => item.id === id);
    //     console.log(found_forCount)
    //     if(found_forCount.count == 1) {
    //       orderOld_toArr = orderOld_toArr.filter(item => item.id !== id);
    //     }else {
    //       orderOld_toArr[found].count -= 1;
    //     }
    //   }
    //   if(orderOld_toArr.length == 0)
    //     localStorage.removeItem("order")
    //   else
    //     localStorage.setItem("order",JSON.stringify(orderOld_toArr));
    // }

    return plate.count;
  }





  
  function getOrderReadyToWhatsapp() {
    let whatsappMassage = "";

    var unit_price = localStorage.getItem("currency-x-x")?.split("/")[0];
    let total = 0;
    orderPlates.forEach((plate) => {
      total += plate.count * plate.menu_price;
    });

    for (let i = 0; i < orderPlates.length; i++) {
      const orderTitle = orderPlates[i].menu_name
        .trim()
        .replaceAll(" ", "%20");
      let count = orderPlates[i].count;
      whatsappMassage += orderTitle + "%20x" + count + "%0A%0A";
    }
    whatsappMassage += "Total:" + total + " " + unit_price;
    whatsappMassage += "%0A%0AComments:";

    return whatsappMassage;
  }


  useEffect(() => {
    orderPlates.forEach((item) => {
      if (item.count === 0) {
        orderPlates.filter((plate) => plate.id !== item.id);
      }
    });
  }, [orderPlates]);



  
  function clearOrder() {
    // setIsLoading(true);
   setOrderPlates([]);


    // localStorage.removeItem("order");
    // localStorage.removeItem("orderExpDate");

    // setFetchAllPlates((oldArr) => {
    //   oldArr.forEach((item) => (item.count = 0));
    //   return oldArr;
    // });

    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 500);
  }
  



    const orderContextValue = {
    orderPlates,
    getOrderReadyToWhatsapp,
    clearOrder,
    onClickAddOne,
    onClickRemoveOne,
  };

  
  return (
        <OrderContext.Provider value={orderContextValue}>
            {props.children}
        </OrderContext.Provider>
  );



}




export const GenreContextProvider = (props) => {
  const [fetchAllPlates, setFetchAllPlates] = useState([]);
  const [fetchedPlates, setFetchedPlates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedCategories, setFetchedCategories] = useState([]);
  const [showSubGenrePlates, setShowSubGenrePlates] = useState(false);
  const [showSubGenre, setShowSubGenre] = useState(false);
  // const [orderPlates, setOrderPlates] = useState([]);
  const [genreOpen, SetGenreOpen] = useState(-1);
  const [error, setError] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [dollarRate, setDollarRate] = useState([]);
  const [exCurrency, setExCurrency] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [currentCurrency, setCurrenctCurrency] = useState();
  const [currentLanguage, setCurrentLanguage] = useState();
  const [test, setTest] = useState(false);

  function changeCurrentLanguage(items) {
    // orderPlates = [];
    setCurrentLanguage(items);
    // fetchPlatesByCategory_lang_curr();
  }

  async function getCategorySection(promise) {

    let url = "https://aboudeeb.com.de/admin/apis/SiteController.php/categories";
    
    const returnedObj = await fetchHelper(url, "GET");
    // console.log(JSON.parse(returnedObj.data));
    let error = null;

    if (returnedObj?.message?.error) {
      error = returnedObj.message.content;
      setError(returnedObj.message.content);
    } else {
      setError(null);
      const categories = [];
      let betterObject = {};
      returnedObj.data.forEach((item) => {
          betterObject = {
            id: item.category_id,
            label: item.category_name,
            image: "",
            createdBy: "",
            parent: null,
            isMenu:
              item.category_name.toUpperCase().includes("MENU") ||
              item.category_name.toUpperCase().includes("ALL") ||
              item.category_name.trim() === ""
                ? true
                : false,
            isSub: false,
          };
        categories.push(betterObject);
      });
      if (promise) {
        return new Promise((resolve, reject) => {
          if (error) {
            reject(error);
          } else {
            resolve({categories});
          }
        });
      }
    }
  }

  // // FETCH ALL PLATES
  function fetchPlatesByCategory(category_id, initVal, initCategories) {
    var currency = localStorage.getItem("currency-x-x");
    SetGenreOpen(category_id);
    if (fetchAllPlates.length == 0) {
      var url = `https://aboudeeb.com.de/admin/apis/SiteController.php/menuItems`;
     
      fetchHelper(url, "GET").then(function (data) {
        const betterObj = [];
        data.data.forEach((item) => {
          betterObj.push({
            ...item,
            count: 0,
          });
        });

        const sortedArray = sortArray(betterObj, initCategories);
        setFetchedPlates(sortedArray);

        setFetchAllPlates(betterObj);
      });
      setIsLoading(false);
    } else {
      setIsLoading(true);
      // fetchedPlates
      SetGenreOpen(category_id);
      if (category_id == 0) {
        if (initVal) {
          const sortedArray = sortArray(initVal, fetchedCategories);
          setFetchedPlates(sortedArray);
        } else {
          const sortedArray = sortArray(fetchAllPlates, fetchedCategories);
          setFetchedPlates(sortedArray);
        }
        setIsLoading(false);
        return;
      }
      var newArr = [];
      fetchAllPlates.forEach((item) => {
        if (item.category_id == category_id) {
          newArr.push(item);
        }
      });
      // setTimeout(() => {
        const sortedArray = sortArray(newArr, fetchedCategories);
        setFetchedPlates(sortedArray);
      // }, 400);
      setIsLoading(false);
    }
  }

  function fetchPlatesByCategory_lang_curr(category_id) {
    setIsLoading(true);
    SetGenreOpen(0);
    var currency_id = localStorage.getItem("currency-x-x");
    var language_id = localStorage.getItem("language_menumind");
    var url = `https://aboudeeb.com.de/admin/apis/SiteController.php/menuItems?`;
    if (currency_id) {
      url += `currency_id=${currency_id.split("/")[1]}&`;
    }
    if (language_id) {
      url += `lang_id=${language_id.split("/")[1]}&`;
    }
    if (category_id) {
      url += `category_id=${category_id}`;
    }

    fetchHelper(url, "GET").then(function (data) {
      const betterObj = [];
      data.data.forEach((item) => {
        betterObj.push({
          ...item,
          count: 0,
        });
      });
      const sortedArray = sortArray(betterObj, fetchedCategories);

      setFetchAllPlates(betterObj);
      setFetchedPlates(sortedArray);
      // fetchPlatesByCategory(0, betterObj);
      setIsLoading(false);
    });
  }
  // function fetchPlatesByCategory(category_id) {
  //   var currency_id = localStorage.getItem("currency-x-x");
  //   var language_id = localStorage.getItem("language_menumind");
  //   var url =`https://aboudeeb.com.de/admin/apis/SiteController.php/menuItems?`;
  //   if(currency_id) {
  //     url+=`currency_id=${currency_id.split("/")[1]}&`;
  //   }
  //   if(language_id) {
  //     url+= `lang_id=${language_id.split("/")[1]}&`
  //   }
  //   if(category_id) {
  //     url+= `category_id=${category_id}`
  //   }

  //   fetchHelper(
  //     url,
  //     "GET"
  //     ).then(function(data) {
  //       const betterObj = [];
  //       data.data.forEach((item) => {
  //         betterObj.push({
  //           ...item,
  //           count:0
  //         })
  //       })
  //       setFetchAllPlates(betterObj)
  //     })

  // }

  // function fetchPlatesByCategory(catId, initData, initCategories) {
  //   let plates = [];
  //   let subgenre = [];
  //   setIsLoading(true);
  //   const subCategories = [];
  //   fetchedCategories.forEach((item) => item.isSub && subgenre.push(item));

  //   subgenre = subCategories.filter((item) => item.parent === catId);

  //   if (catId === -1) {
  //     initData ? (plates = initData) : (plates = fetchAllPlates);
  //   } else {
  //     plates = fetchAllPlates.filter((item) => item.genreId === catId);
  //   }

  //   plates.length === 0
  //     ? (platesContainerIsEmpty = true)
  //     : (platesContainerIsEmpty = false);

  //   const sortedArray = sortArray(plates, initCategories || fetchedCategories);

  //   subgenre.length !== 0 ? setShowSubGenre(true) : setShowSubGenre(false);
  //   setShowSubGenrePlates(false);
  //   subGenrePlates = [];
  //   SetGenreOpen(catId);
  //   fetchedPlates = sortedArray;
  //   setIsLoading(false);
  // }

  // function fetchPlatesByCategory_currency_lang(lang_id, currency_id,category_id) {
  //   // split by last slash to get filename and then /thumbnail/filename
  //   var url =`https://aboudeeb.com.de/admin/apis/SiteController.php/menuItems?currency_id=${currency_id}&lang_id=${lang_id}`;
  //   if(category_id) {
  //     url = `https://aboudeeb.com.de/admin/apis/SiteController.php/menuItems?currency_id=${currency_id}&lang_id=${lang_id}&category_id=${category_id}`
  //   }
  //   fetchHelper(
  //     url,
  //     "GET"
  //     ).then(function(data) {
  //       console.log(data.data)
  //       // fetchedPlates(data.data);
  //       setFetchAllPlates(data.data)
  //     })
  //   }

  function fetchPlatesBySubGenre(subGenreId) {
    setIsLoading(true);
    const subGenresPlatesArr = fetchedPlates.filter(
      (plate) => plate.subGenreId === subGenreId
    );
    subGenrePlates.length === 0
      ? (platesContainerIsEmpty = true)
      : (platesContainerIsEmpty = false);

    const sortedArr = sortArray(subGenresPlatesArr, fetchedCategories);
    setIsLoading(false);
    setShowSubGenrePlates(true);
    subGenrePlates = sortedArr;
  }

  useEffect(() => {
    // GET ORDER PLATES FROM LS
    // const orderItems = JSON.parse(localStorage.getItem("order"));
    // if(orderItems)
    // orderPlates = orderItems;

    if (fetchAllPlates.length > 0 && fetchedCategories.length > 0) {
      return;
    }
    fetchHelper(
      // https://catalogcms2.menumind.net/v1_0_0-data/get-categories?lang_id=1
      "https://aboudeeb.com.de/admin/apis/SiteController.php/categories",
      "GET"
    ).then((data) => {
      if (data.message.error) {
        setError(data.message.content);
      } else {
        // const updatedPlates = [];
        // console.log(data.data)
        // alert("HERE")
        // data.data.forEach((item) => {
        //   const betterObj = {
        //     id: item.id,
        //     title: item.menu_name,
        //     description: item.description,
        //     menu_price: item.menu_price,
        //     image: item.main_image,
        //     created_by: item.created_by,
        //     count: 0,
        //     subGenreId: item.r_sub_id,
        //     genreId: item.r_category_id,
        //     language: item.language,
        //     r_currency_id: item.r_currency_id,
        //   };
        //   updatedPlates.push(betterObj);
        // });

        // UPDATE ORDER PLATES TO MATCH THE LS PLATES___IF THERE IS ANY
        // if(orderItems) {
        //   updatedPlates_ls = updatedPlates.map(obj1 => {
        //   const matchingObj2 = orderItems.find(obj2 => obj2.id === obj1.id);
        //   const l = [];
        //   if (matchingObj2) {
        //       return matchingObj2;
        //     } else {
        //       return obj1;
        //     }
        //   });
        // }

        getCategorySection(true).then((data) => {
          setError(null);
          setFetchedCategories(data.categories);
          fetchPlatesByCategory(0, null, data.categories);
          // setFetchAllPlates(updatedPlates_ls);
          // fetchPlatesByCategory(-1, updatedPlates_ls, data.categories);
        });
      }
    });
  // }, []);

  setCurrency([{currency_name:"Lebanese Lira",id:1,currency_shortuct:"L.L"},{currency_name:"US Dollar",id:2,currency_shortuct:"$"} ]);
    fetchHelper(
      "https://aboudeeb.com.de/admin/apis/SiteController.php/dollarRate",
      "GET"
    ).then((data) => {
      if (data.message.error) {
        setError(data.message.content);
      } else {
        setDollarRate(data.data[0].rate)
      }
    });


    //   fetchHelper(
    //     "https://menumind.net/menumind/web/v1_0_0-items/get-ex-currency",
    //     "GET"
    //     ).then((data) => {
    //     setExCurrency(data)
    // })

  }, []);

  function changeCurrentCurrency(currency) {
    // orderPlates = [];
    setCurrenctCurrency(currency);
    
    // fetchPlatesByCategory();

    fetchPlatesByCategory_lang_curr();
  }

  function getCount(id) {
    return fetchAllPlates.find((item) => item.id == id).count;
  }

  // REMOVE ONE TO THE COUNT OF A PLATE
  // function onClickAddOne(id) {
  //   console.log(id)
  // }


  // ORDER LOCAL STORAGE-----------------------
  // useEffect(() => {
    // check if the order date has expired and delete it if it is
    // const expDate =localStorage.getItem("orderExpDate");
    // if(expDate < Date.now()) {
    //   // localStorage.removeItem("order");
    //   // localStorage.removeItem("orderExpDate");
    // }
  // }, [orderPlates]);
  // end------------------------------------------------------end




  const genreContextValue = {
    fetchedCategories,
    showSubGenrePlates,
    genreOpen,
    error,
    fetchPlatesByCategory,
    fetchPlatesBySubGenre,
    showSubGenre,
    changeCurrentLanguage,
    fetchedPlates,
    isLoading,
  };


  const plateContextValue = {
    showSubGenrePlates,
    fetchPlatesByCategory,
    getCount,
    // fetchPlatesByCategory_currency_lang,
  
    fetchPlatesBySubGenre,
    subGenrePlates,
    isLoading,
  
    platesContainerIsEmpty,
    currency,
    fetchAllPlates,
    exCurrency,
    changeCurrentLanguage,
    changeCurrentCurrency,
    currentCurrency,
    languages,
    setCurrentLanguage,
    currentLanguage,
  };



  return (
    <PlateContext.Provider value={plateContextValue}>
      <GenreContext.Provider value={genreContextValue}>
        {props.children}
      </GenreContext.Provider>
    </PlateContext.Provider>
  );
};
