import classes from './Menu.module.css';
import Category from '../../components/menuSection/items/Category';
import Header from '../../components/menuSection/header/Header';
import Plate2 from '../../components/menuSection/plates/Plate2';
import MiniOrder from '../../components/menuSection/order/MiniOrder';
import { useContext, useEffect } from 'react';
import {GenreContext} from '../../store/GenreContext';
import SubCategory from '../../components/menuSection/items/subCategory/SubCategory';
import fetchHelper from '../../components/hooks/fetchHelper';
import PlatesContainer from '../../components/menuSection/plates/PlatesContainer';

const Menu = props => {
  const ctx = useContext(GenreContext)

  if(ctx.error) {
    return <h1 className="error">{ctx.error} ,<span>please refresh the page :{")"}</span></h1>
  }


  return (
    <>
      <div className={`${classes.main}`}>
        <div className={classes.main2}>
          <Header />
          <Category/>
          {/* <SubCategory /> */}
        </div>
        {/* <PlatesContainer/> */}
        {/* <Plate2 /> */}
        <PlatesContainer />
      <MiniOrder />
    </div>
    </>
  )
}

export default Menu;