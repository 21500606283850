import Genre from '../../ui/Genre/Genre';
import classes from './Category.module.css';
import item1 from '../../../images/Items/category 1.png';
import React, { useContext } from 'react';
import {GenreContext} from '../../../store/GenreContext';
import SubCategory from './subCategory/SubCategory';
import CategorySlider from '../../ui/CategorySlider/CategorySlider';


const Category = () => {
  const ctx = useContext(GenreContext);
  const {fetchedCategories,genreOpen,showSubGenre} = ctx;

  
  return (
    <>
    <div className={`${classes.gridContainerr} contain`}>
      <CategorySlider data={fetchedCategories}>
        {
          fetchedCategories.map((item) => {
            return item.isMenu && <Genre activeId={genreOpen} key={item.id} src={item.image} id={0} label={item.label} /> 
          })
        }
      {
        fetchedCategories.map((item) => 
        !item.isMenu &&
        <Genre activeId={genreOpen} key={item.id} src={item.image} id={item.id} label={item.label} />
        )
      }   
      </CategorySlider>
    </div>
      {showSubGenre && <SubCategory />}
      <div className={classes.borderBottom}></div>   
    </>
  )
}

export default React.memo(Category);