import {useContext, useEffect} from "react";
import {OrderContext} from "../../../store/GenreContext";
import { getCurrency_updated, getMenuItemPrice, getRate } from "../../hooks/fetchHelper";
import classes from "./Subtotal.module.css";
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { useState } from "react";

const Subtotal = (props) => {
  const {languagePosition} = props;
  const ctx = useContext(OrderContext);

  const [showModal, setShowModal]= useState(false);

    
  const totalQty = ctx.orderPlates.reduce(
    (accumulator, plate) => accumulator + plate.count,
    0
  );

  

  let total = 0;
  ctx.orderPlates.forEach((plate) => {
    total += plate.count * plate.menu_price;
  });



  const [totalPrice, setPrice] = useState(total);

  useEffect(() => {
    (async function x() {
      const price = await getMenuItemPrice(total,true)
      setPrice(price);
    })()
  })

  


  var currency_ref = localStorage.getItem("currency-x-x")?.split("/")[0];

  const currencyId = ctx.currentCurrency?.split('/')[1];
  // RATE
  // const rateObj = getRate(1,currencyId,ctx.exCurrency,ctx.currency);
  // const to_currency = localStorage.getItem("currency");
  // console.log(ctx.exCurrency);
  // let rateObj = getCurrency_updated(to_currency.split("/")[1],currencyId,ctx.exCurrency,ctx.currency);

  const updatedPrice =`${totalPrice} ${currency_ref}`;

  // init swal
  const MySwal = withReactContent(Swal)
  
  // useEffect(() => {
  //   MySwal.fire({
  //     title: <p>Hello World</p>,
  //     didOpen: () => {
  //       // `MySwal` is a subclass of `Swal` with all the same instance & static methods
  //       MySwal.showLoading()
  //     },
  //   }).then((data) => {
  //     console.log(data);
  //   })

  // }, [ctx.showModal])
  
  //   Swal.fire({
  //   title: 'Are you sure?',
  //   text: "You won't be able to revert this!",
  //   icon: 'warning',
  //   showCancelButton: true,
  //   confirmButtonColor: '#3085d6',
  //   cancelButtonColor: '#d33',
  //   confirmButtonText: 'Yes, delete it!'
  // }).then((result) => {
  //   if (result.isConfirmed) {
  //     Swal.fire(
  //       'Deleted!',
  //       'Your file has been deleted.',
  //       'success'
  //     )
  //   }
  // })

  return (
    <>
      {ctx.orderPlates.length > 0 && (
        <div
          className={` ${props.className} ${classes.flex} ${
            props.fixed ? props.fixed : ""
          } ${languagePosition == "rtl" ? classes.rightToLeft : null}`}
          onClick={props.onClickOpenOrder}>
            
          <h2 className={classes.qty}>
            {/* {languagePosition === "rtl" ? ":الكمية" : "Qty:"} */}
            Quantity:
            <span>{totalQty}</span>
          </h2>
          <h3 className={classes.total}>
            {/* {languagePosition === "rtl" ? ":المجموع" : "total:"}{" "} */}
            total:
            <span>{updatedPrice}</span>
          </h3>
        </div>
      )}
    </>
  );
};

export default Subtotal;
