import Slider from 'react-slick';

// slick carousel
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import CarouselArrow from '../../CarouselArrow/CarouselArrow';


const CategorySlider = ({children,bgDark,data}) => {
  
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 7,
    slidesToScroll: 1,
    speed: 200,
    initialSlide: 0,
    swipeToSlide: true,
    arrows:false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          slidesToShow: 4,
          slidesToScroll:1
        }
      }
    ]
  };



  return (
    <>
    {
      data.length !== 0 ? 
      <div className={`slickContainer`}>
      <Slider
      {...settings}
      >
     {children}
        </Slider>
      </div>
      : null
    }
    </>

  )


}

export default CategorySlider;