import classes from "./Focus2.module.css";
import PlateModal from "../../ui/Modal/PlateModal";

const PlateFocus = (props) => {
  // const [sliderImages, setSliderImages] = useState({
  //   images: [],
  //   prdouct_info: {},
  // });
  const {is_only_image, modal_id} = props;

  const {
    menu_name,
    menu_image,
    menu_price,
    menu_description,
    menu_id:id,
    count,
    currency_shortcut,
  } = props.plate;

  const p = document.createElement("p");
  p.innerHTML = menu_description;

  // useEffect(() => {
  //   fetchHelper(
  //     `/get-product-details?product_id=${id}`,
  //     "GET"
  //   ).then((data) => {
  //     if (!data.message.error) {
  //       setSliderImages(JSON.parse(data.data));
  //     }
  //   });
  // }, []);

  const imageSrc = `${menu_image}`;

  // LANGUAGE COMPONENT
  // const languageArr = language?.split("/");
  // const languageId = languageArr[1];
  // const languagePosition = languageArr[2];

  // const currencyId = ctx.currentCurrency?.split("/")[1];

  // RATE

  return (
    <PlateModal
      is_only_image={is_only_image}
      menu_image={menu_image}
      className="m-0"
      currency_shortcut={currency_shortcut}
      menu_price={menu_price.toLocaleString()}
      flex={classes.flex}
      id={id}
      modal_id={modal_id}
      count={count}
      menu_description={menu_description}
      menu_name={menu_name}
      plate={props.plate}
      sliderImages={[`https://aboudeeb.com.de/admin/admin/Uploads/images/${menu_image}`]}
      src={imageSrc}
      show={props.isOpen}
      onHide={props.onClickHide}
    />
  );
};

export default PlateFocus;
