import React from 'react';
import {createRoot} from 'react-dom/client';
import { BrowserRouter as Router,HashRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { GenreContextProvider, OrderContextProvider } from './store/GenreContext';

const container = document.getElementById('root');
const root = createRoot(container)
root.render(	
<HashRouter>
	<GenreContextProvider>
	<OrderContextProvider>
		<App />
	</OrderContextProvider>
	</GenreContextProvider>
</HashRouter>
)
