import {PlateContext} from "../../../store/GenreContext";
import classes from "./Plates2.module.css";
import PlatesInner from "./PlatesInner";
import React, {useContext} from "react";

const Plates = (props) => {
  const ctx = useContext(PlateContext);

  // LANGUAGE COMPONENT
  const {language} = props;
  const {
    category,
    index,
    groupArr,
    plates
  } = props;

  const {isLoading} = ctx;



  // console.log(props);
  const languageArr = language?.split("/");

  let languagePosition;
  if (languageArr) {
    languagePosition = languageArr[2];
  }

  
  console.log("Plate.js")
  
  return (
    <section key={Math.random()*100} >
      {isLoading ? (
        <div key={Math.random()*1000} className="spinner"></div>
      ) : plates && plates.length !== 0 ? (
        <>
          <div
            key={index}
            className={`${classes.flexCat} ${
              languagePosition === "rtl" ? classes.rightToLeft : null
            }`}>
            <div className={classes.category}>
              {category.label.toUpperCase()}
            </div>
            {/* <div className={classes.smImage}>
              {category.image !== null ? (
                <img
                  style={{border: "2px solid"}}
                  src={imageSrc}
                  hidden={hideImage}
                  onError={onErrorImageHandler}
                />
              ) : (
                <div></div>
              )}
            </div> */}
          </div>
          {plates.map((plate) => {
            return (
              <PlatesInner
                languagePosition={languagePosition}
                language={language}
                key={plate.id}
                plate={plate}
              />
            );
          })}
          {index === groupArr.length - 1 ? (
            <hr className={classes.end} />
          ) : index + 1 < groupArr.length &&
            groupArr[index + 1][1].length === 0 ? (
            <hr className={classes.end} />
          ) : (
            <hr className={classes.hrCategory} />
          )}
        </>
      ) : null}
    </section>
  );
};

export default Plates;
