import { useContext,useState } from 'react';
import {GenreContext, PlateContext} from '../../../../store/GenreContext';
import classes from './SubCategory.module.css';
import { Link } from 'react-scroll';


const SubCategory = ({items}) => {

  const ctx = useContext(GenreContext);
  const {fetchedCategories} = ctx;
  const [active,setActive] = useState(0);
  

  function onClickGetPlates(id) {
    setActive(id)
    ctx.fetchPlatesBySubGenre(id)
  }
  
  const offset = window.innerWidth < window.innerHeight ? (100 - 70) * (window.innerHeight / 100) 
  : (100) * (window.innerHeight / 100);

  const isSubCategory = fetchedCategories.map((item) => 
  !item.isSub &&
  <span className={`${active === item.id ? classes.active : null}`} onClick={onClickGetPlates.bind(this,item.id)} key={item.id}>{item.label}</span>);
  
  return (
    <>
    <Link offset={offset} smooth={true} duration={300} to="topSection">
      <div className={classes.main}>
        {isSubCategory}
      </div>
    </Link>
    </>
  )
}

export default SubCategory;