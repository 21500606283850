import classes from './PlatesContainer2.module.css'
import  {useContext, useEffect } from 'react';
import {GenreContext, OrderContext, PlateContext} from '../../../store/GenreContext';
import Plates from './Plate';

const PlatesContainer = (props) => {
  const ctx = useContext(GenreContext);
  const {fetchedPlates} = ctx;
  const {currentLanguage: language} = ctx;

 
  console.log("PLATESCONTAINER.js")
  console.log(fetchedPlates)


  // useEffect(() => {
  //   console.log('isLoading changed:', isLoading);
  // }, [isLoading]);

  // useEffect(() => {
  //   console.log('currentCurrency changed:', currentCurrency);
  // }, [currentCurrency]);

  // useEffect(() => {
  //   console.log('exCurrency changed:', exCurrency);
  // }, [exCurrency]);

  // useEffect(() => {
  //   console.log('currency changed:', currency);
  // }, [currency]);


  return (
        <div className={`${classes.MainGrid}`}>
          {
            // !platesContainerIsEmpty ? 
            fetchedPlates?.map((groupArr,index,arr) => {
              if(groupArr[1]?.length !== 0 )
              return <Plates  language={language} key={groupArr[0].id} category={groupArr[0]} plates={groupArr[1]} index={index} length={groupArr.length} groupArr={arr}/>
          }) 
          
          // : <div className={classes.noItems}>There are no items in here yet :{")"}</div>

        }

    </div>
  )

}

export default PlatesContainer;