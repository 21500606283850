import classes from "./Slideshow.module.css";
import default_image from "../../images/mainLogo.png";

const Slideshow = () => {

  return (
    <div className={`slideshow_carousel ${classes.carouselMain}`}>
      <img src={default_image} alt="Main Logo" className={classes.mainLogo}/>
    </div>
  );
};

export default Slideshow;
