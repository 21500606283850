import Modal from "react-bootstrap/Modal";

import AddRemCount from "../AddRemCount";
import { useEffect, useState } from "react";
import { getMenuItemPrice } from "../../hooks/fetchHelper";

const PlateModal = (props) => {
  const p = document.createElement("p");
  p.innerHTML = props.menu_description;
  const [price, setPrice] = useState(props.menu_price);


  useEffect(() => {
    (async function x() {
      const price = await getMenuItemPrice(props.menu_price)
      setPrice(price);
    })()
  },[props.menu_price])

  return (
    (<Modal
    key={p}
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="m-auto"
      style={{background: "transparent", backdropFilter: "blur(10px)"}}
      centered>
      {props.is_only_image === false ? (
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.menu_name}
          </Modal.Title>
        </Modal.Header>
      ) : (
        <button
          type="button"
          className="btn-close"
          style={{marginLeft: "auto", padding: "1rem 1rem 0 0"}}
          aria-label="Close"
          onClick={props.onHide}></button>
      )}
      <Modal.Body>
        {/* <Image src={props.src} fluid /> */}
        {props.main_image !== null && (
          <div>
            {/* <CarouselSlick top="77vh" data={props.sliderImages}>
              {props.sliderImages.map((image) => {
                return (
                  <SlideshowImage2
                    className={props.is_only_image === true ? "whole_image" : ""}
                    image={image}
                    styling={{}}
                  />
                );
              })}
            </CarouselSlick> */}
            <div top="77vh" data={props.sliderImages}>
                <img style={{margin: "auto",display: "block",maxWidth: "100%",maxHeight: "50vh"}} alt="image could not be found !" src={`https://aboudeeb.com.de/admin/admin/Uploads/images/${props.menu_image}`}/>
            </div>
          </div>
        )}

        {props.is_only_image === false ? (
          <>
            <p className="mt-3">{p.textContent}</p>
            <div className={props.flex}>
              <span >
                {price} {localStorage.getItem("currency-x-x")}
              </span>
              <AddRemCount
                className={props.flex}
                id={props.id}
                qty={props.count}
                plate={props.plate}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={props.onHide} style={{backgroundColor:"#E77728", "border":"none"}}>Close</Button> */}
      </Modal.Footer>
    </Modal>)
  );
};

export default PlateModal;
