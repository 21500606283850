import {useContext} from "react";
import whatsappLogo from "../../images/whatsappLogo.png";
import {OrderContext} from "../../store/GenreContext";
import classes from "./Whatsapp.module.css";

const Whatsapp = ({phone_number}) => {
  const ctx = useContext(OrderContext);
  const {getOrderReadyToWhatsapp} = ctx;

  // derivedState
  let whatsappMassage = getOrderReadyToWhatsapp();

  phone_number &&= phone_number.replace(/\s/g, "");

 return (
    <div></div>
  )
  return (
    <div className={classes.imageContainer}>
      <a
        target={"_blank"}
        href={`https://wa.me/${phone_number}?text=Hello,%20I%20would%20like%20to%20order:%0A${whatsappMassage}`}>
        <img src={whatsappLogo} />
      </a>
    </div>
  );
};

export default Whatsapp;
