import {Route, Routes} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Slideshow from "./components/header/Slideshow";
import Menu from "./pages/Menu/Menu";
import Loading from "./images/Loading_icon.gif";
import Whatsapp from "./components/whatsapp/Whatsapp";
import fetchHelper from "./components/hooks/fetchHelper";
//   "homepage": "/ali/react/",

const About = React.lazy(() => import("./pages/About/About"));

const App = () => {
  const [aboutContent, setAboutContent] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // GET ABOUT CONTENT
  // useEffect(() => {
  //   setIsLoading(true);
  //   fetchHelper(
  //     "https://catalogcms2.menumind.net/v1_0_0-data/about-us",
  //     "GET"
  //   ).then((data) => {
  //     if (data.message.error) {
  //       setError(data.message.content);
  //     } else {
  //       setAboutContent(JSON.parse(data.data));
  //     }
  //   });

  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 4000); // Change the delay time as per your requirement
  // }, []);

  return (
    <div>
      {
        // isLoading ? (
        //   <img
        //     src={brainkets_loader}
        //     alt="Powered By Brainkets..."
        //     style={{width: "100%", height: "100vh", objectFit: "cover"}}
        //   />
        // ) :

        <div className="relative">
          <Slideshow />
          <Whatsapp phone_number={"+96170740808"} />
          {/* <a
          target={"_blank"}
            href="https://wa.me/+96176891955?text=Hello Ali, can you explain more about what you do ?"
            className="powered_by"
            style={{transition: ".4s"}}>
            <span>Crafted By </span> <span style={{color:"#EE1D23", fontWeight:"600"}}> Ali Alfakih <span>+961 76 891 955</span></span>
          </a> */}
          
          <Routes>
            <Route path="/" element={<Menu />} />
            <Route
              path="/about"
              element={
                <React.Suspense
                  fallback={
                    <div className="loadingFallback">
                      <img src={Loading} alt="Loading..." />
                    </div>
                  }>
                  <About
                    aboutContent={aboutContent}
                    error={error}
                    isLoading={isLoading}
                  />
                </React.Suspense>
              }
            />
          </Routes>
        </div>
      }
    </div>
  );
};

export default App;
