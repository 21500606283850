import classes from "./OrderPlate.module.css";
import AddRemCount from "../../ui/AddRemCount";
import {useEffect, useState} from "react";
import { getMenuItemPrice } from "../../hooks/fetchHelper";

const OrderPlates = (props) => {
  const {language} = props;
  const {main_image, menu_name:title, count, menu_price, menu_id:id} = props.plate;
  const [price, setPrice] = useState(menu_price);


    useEffect(() => {
    (async function x() {
      const price = await getMenuItemPrice(menu_price, false)
      setPrice(price);
    })()
  },[])
  const [hideImage, setHideImage] = useState(false);

  let imageSrc = `https://aboudeeb.com.de/admin/admin/Uploads/images/${main_image}`;

  // RATE
  return (
    <>
      <div
        className={`${classes.flex} ${
          language === "Ar" ? classes.rightToLeft : null
        }`}>
        <div className={classes.section1}>
          <div className={classes.image}>
            {main_image !== null ? (
              <img
                src={imageSrc}
                hidden={hideImage}
                alt={title}
                onError={() => setHideImage(true)}
              />
            ) : (
              <div></div>
            )}
          </div>
          <div className={classes.flexcol}>
            <h1>{title}</h1>
            <p className={classes.total}>
              Total:{" "}
              <span>{(parseInt(count) * price).toLocaleString()} {localStorage.getItem("currency-x-x")}</span>
            </p>
          </div>
        </div>
        <div className={classes.section3}>
          <AddRemCount ctx={props.ctx} qty={count} id={id} plate={props.plate} />
          <span>Qty:</span>
          {count}
        </div>
      </div>
      <div className="linebreak"></div>
    </>
  );
};

export default OrderPlates;
